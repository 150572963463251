import axios, {Method} from "axios";
import qs from 'qs';
import {getToken} from "./token";

const baseUrl = '/api/';

export const request = <T>(path: string, params?: { [k: string]: string }, data?: any, method?: Method) => {
    const headers: {
        [Key: string]: string;
    } = {};
    
    if (getToken()) {
        headers['Authorization'] = `Token ${getToken()}`;
    }
    
    return axios.request<T>({
        method: method || 'GET',
        url: path,
        baseURL: baseUrl,
        params: qs.stringify(params),
        headers: headers,
        data: data
    }).then(r => r.data);
};