import React, {useContext} from 'react';
import {Route} from 'react-router';

import {useHistory} from "react-router-dom";
import {setToken} from "./api/token";
import {Layout} from "./pages/shared/Layout";
import {Home} from "./pages/home/Home";
import {Faq} from "./pages/faq/Faq";
import {WeddingContext, WeddingContextProvider} from "./WeddingContext";
import {Wishlist} from "./pages/wishlist/Wishlist";
import {Game} from "./pages/game/Game";

const App = () => {
    const history = useHistory();
    const {reloadContext} = useContext(WeddingContext);
    const hash = history.location && history.location.hash;

    if (hash.length > 2) {
        const token = hash.substr(1);
        setToken(token);
        reloadContext();
    }

    return (
        <WeddingContextProvider>
            <Layout>
                <Route exact path='/' component={Home}/>
                <Route path='/faq' component={Faq}/>
                <Route path='/wishlist' component={Wishlist}/>
                <Route path='/game' component={Game}/>
            </Layout>
        </WeddingContextProvider>
    );
}

export default App;
