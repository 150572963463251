import {Col, Container, Row} from 'react-bootstrap';
import Unity, {UnityContext} from "react-unity-webgl";
import {useCallback, useContext, useEffect, useState} from "react";
import {Score} from "../../api/models";
import {addScore, getScores} from "../../api/scores";
import {WeddingContext} from "../../WeddingContext";
import {getToken} from "../../api/token";
import {
    isMobile, isDesktop
} from "react-device-detect";
import AnchorLink from "react-anchor-link-smooth-scroll";

const unityContext = new UnityContext({
    loaderUrl: "/static_content/game/web.loader.js",
    dataUrl: "/static_content/game/web.data",
    frameworkUrl: "/static_content/game/web.framework.js",
    codeUrl: "/static_content/game/web.wasm",
});

type RecordsProps = {
    scores: Score[];
}

const Records = ({scores}: RecordsProps) => {
    const token = getToken();

    return <>
        <Row className="justify-content-center">
            <Col lg={6}>
                {isDesktop && <>
                    Разворачиваться по стрелочке влево и вправо. Лететь вперед по стрелочке вверх. Стрелять по Z, а по X
                    мощным лазером, который перезаряжется раз в 7 секунд.
                </>}
                {isMobile && <>
                    В эту игру можно играть с компа. Для этого скопируй и открой открой
                    эту <a href={"/game#" + token}>ссылку</a> на нем.
                </>}
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col lg={6} className="text-center">
                Победитель получит что-то, но что именно мы еще не решили... Вот кстати рекорды
            </Col>
        </Row>

        {scores.map((s, i) => <Row className="justify-content-center w-100" key={i}>
            <Col lg={6} xs={10}>
                {i + 1}. {s.name}
            </Col>
            <Col lg={2} xs={2}>
                {s.value}
            </Col>
        </Row>)}
    </>;
};

export const Game = () => {
    const [progression, setProgression] = useState(0);
    const {guest} = useContext(WeddingContext);
    const [scores, setScores] = useState<Score[]>([]);

    const loadScores = useCallback(() => {
        getScores()
            .then(s => {
                setScores(s);
            })
            .catch(console.log)
    }, [setScores]);

    useEffect(() => loadScores(), [loadScores]);

    useEffect(function () {
        unityContext.on("progress", function (progression) {
            setProgression(progression);
        });
        unityContext.on("GameOver", function (score) {
            if (guest !== null) {
                addScore(score)
                    .then(loadScores);
            }
        });
    }, [guest, loadScores]);


    return <>
        {isDesktop && <Container className="mt-5">
            {progression < 1 && <p>Грузим... {progression * 100}%</p>}
            <Unity
                unityContext={unityContext}
                style={{
                    height: "100%",
                    width: "100%"
                }}
            />
            {!guest && <p className="pb-0 mb-0 ps-2 pe-2 alert-danger">
                Вы не вошли на сайт, рекорд сохранен не будет
            </p>}
            <p className="align-content-center">
                \/\/\/ <AnchorLink href="#records-and-rules" className="highlight">рекорды и
                управление</AnchorLink> \/\/\/
            </p>
        </Container>}
        <Container id="records-and-rules">
            <Records scores={scores}/>
        </Container>
    </>
};