import React, {Suspense} from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Col, Container, Row} from "react-bootstrap";
import {Canvas} from "@react-three/fiber";
import {Environment, OrbitControls} from "@react-three/drei";
import {Card, CardProps} from "./Card";
import {Loading} from "../shared/Loading";

const polarAngleMaxDev = Math.PI / 4;
const polarAngleBase = Math.PI / 2;


export const Letter = (props: CardProps) =>
    <Container className="letter mt-0">
        <Suspense fallback={<Loading/>}>
            <div style={{"flex": "1 0", width: "100%"}}>
                <Canvas dpr={1.5}
                        gl={{alpha: true, stencil: false, depth: false, antialias: true}}
                        camera={{position: [0, 0, 20], fov: 35}}>
                    <ambientLight intensity={1}/>
                    <Card {...props}/>
                    <Environment preset="studio"/>
                    <OrbitControls enablePan={false} enableZoom={false}
                                   minPolarAngle={polarAngleBase - polarAngleMaxDev}
                                   maxPolarAngle={polarAngleBase + polarAngleMaxDev}/>
                </Canvas>
            </div>
        </Suspense>
        <Row className="big">
            <Col>
                Ого, это ваша открытка? Кажется, она
                про <AnchorLink href="#short-info" className="highlight">нашу свадьбу</AnchorLink>
            </Col>
        </Row>
    </Container>;