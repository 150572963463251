import React, {useCallback, useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Guest} from "../../api/models";
import {Link} from 'react-router-dom';
import {inviteGuest} from "../../api/me";

type ShortInfoProps = {
    guest: Guest | null;
}

export const ShortInfo = ({guest}: ShortInfoProps) => {
    const canInvite = guest?.canInvite ?? false;
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [inviteName, setInviteName] = useState('');
    const [inviteLink, setInviteLink] = useState<string | null>(null);

    const closeModal = () => setShowInviteModal(false);

    const invite = () => {
        setShowInviteModal(true);
    };

    const sendInvite = useCallback(() => {
        inviteGuest(inviteName)
            .then(i => {
                setInviteLink(`${window.location.protocol}//${window.location.hostname}/#${i.inviteCode}`);
            })
    }, [inviteName, setInviteLink]);

    return <Container className="short-info" id="short-info">
        <Row className="justify-content-center">
            <Col md={8} className="text-center">
                Мы решили расписаться, а вас пригласить на тусу с настолками и приставками в гейм-кафе.
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col md={3}>
                <img className="img-responsive fit-image" src="/img/when.png" alt="6 октябре"/>
            </Col>
            <Col md={3}>
                <img className="img-responsive fit-image" src="/img/where.png" alt="В Санкт-Петербурге"/>
            </Col>
        </Row>
        <Row className="justify-content-center mb-4">
            <Col md={6} className="text-center">
                <p>Более подробно можно посмотреть в <Link to="/faq">FAQ</Link>.</p>

                <p>
                    Еще специально для вас мы сделали <Link to="/game">игрушку</Link>, чтобы тот, кто получил больше
                    всего очков, получил от нас какой-то подарок (но мы еще не решили, какой).
                </p>

                В общем, ждем!

                {canInvite && <>
                    <blockquote className="blockquote mt-5">
                        <p className="mb-1">
                            Ой, это все круто, но я не хочу быть в одиночестве...
                        </p>
                        <footer className="blockquote-footer">
                            Могли успеть подумать вы
                        </footer>
                    </blockquote>
                    <p>
                        ...но вы можете <span className="invite" onClick={invite}>пригласить</span> целых
                        1 (одна штука) гостей с собой!
                    </p>
                </>}
            </Col>
        </Row>

        <Modal show={showInviteModal} onHide={closeModal} className="invite-modal">
            <div className="invite-letter">
                <p>Привет,</p>
                <p>
                    {!inviteLink && <input
                        type="text"
                        value={inviteName}
                        onChange={e => setInviteName(e.target.value)}
                        placeholder="имя"
                    />}
                    {inviteLink && <span>{inviteName}</span>}
                </p>

                <p>Приглашаю тебя на свадьбу Егора и Ули</p>

                {!inviteLink && <span onClick={sendInvite} className="invite">Отправить</span>}
                {inviteLink && <a href={inviteLink}>Ссылка приглашение (скопируй)</a>}
            </div>
        </Modal>
    </Container>;
};