import {PresentTarget, Wish} from "../../api/models";
import {useState} from "react";
import {Container, Form, Row, Col} from "react-bootstrap";
import {addWish} from "../../api/wishlist";

type AddWishProps = {
    onWishAdded: (q: Wish) => void;
}

export const AddWish = ({onWishAdded}: AddWishProps) => {
    const [name, setName] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [forYogurt, setForYogurt] = useState<boolean>(true);
    const [forUljana, setForUljana] = useState<boolean>(true);
    const targets: PresentTarget[] = [];
    if (forYogurt) {
        targets.push('Yegor');
    }
    if (forUljana) {
        targets.push('Uljana');
    }

    const add = () => {
        addWish({
            name, link, targets
        }).then(onWishAdded)
    }

    return <Container className="add-questions pt-5">
        <Row className="justify-content-center">
            <Col lg={4}>
                <label htmlFor="name">Название:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col lg={4}>
                <label htmlFor="link">Ссылка на покупку:</label>
                <input
                    type="text"
                    id="link"
                    placeholder="Необязательное"
                    name="link"
                    value={link}
                    onChange={e => setLink(e.target.value)}
                />
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col lg={4}>
                <Form.Check
                    inline
                    id="for-yogurt"
                    label="Для Егора"
                    name="group1"
                    type="checkbox"
                    checked={forYogurt}
                    onChange={e => setForYogurt(e.target.checked)}
                />
                <Form.Check
                    inline
                    id="for-iorving"
                    label="Для ули"
                    name="group1"
                    type="checkbox"
                    checked={forUljana}
                    onChange={e => setForUljana(e.target.checked)}
                />
            </Col>
        </Row>

        <Row className="justify-content-center">
            <Col lg={4}>
                <button onClick={add}>
                    Добавить
                </button>
            </Col>
        </Row>
    </Container>;
}