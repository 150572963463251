import {useCallback, useContext, useEffect, useState} from "react";
import {Loading} from "../shared/Loading";
import {Question} from "../../api/models";
import {deleteQuestion, getQuestions} from "../../api/questions";
import './Faq.scss';
import {WeddingContext} from "../../WeddingContext";
import {AddQuestion} from "./AddQuestion";
import {Col, Container, Row} from "react-bootstrap";

export const Faq = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [questions, setQuestions] = useState<Question[]>([]);
    const {isAdmin} = useContext(WeddingContext);

    const loadQuestions = () => {
        getQuestions()
            .then(q => {
                setQuestions(q);
                setIsLoading(false);
            })
            .catch(console.log)
    };

    const del = (id: number) => {
        deleteQuestion(id)
            .then(() => console.log('deleted question'))
            .then(() => {
                setQuestions([]);
                setIsLoading(true);

                loadQuestions();
            });
    };

    const onAdded = useCallback((q: Question) => {
        questions.push(q);
        loadQuestions();
    }, [questions]);

    useEffect(() => loadQuestions(), [setIsLoading, setQuestions]);

    return <div className="faq">
        {isLoading && <Loading/>}
        <Container className="questions-list">
            {questions.map(q => <Row className="question justify-content-md-center" key={q.id}>
                <Col lg={8}>
                    <p>
                        <span className="tag">Вопрос:</span> {q.text}
                        {isAdmin && <span className="delete" onClick={() => del(q.id)}>delete</span>}
                    </p>
                    <p><span className="tag">Ответ:</span> {q.answer}</p>
                </Col>
            </Row>)}
        </Container>
        {isAdmin && !isLoading && <AddQuestion onQuestionAdd={onAdded}/>}
    </div>
}