import React from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

export const NavMenu = () =>
    <header>
        <Navbar bg="light" expand="lg">
            <Container>
                <LinkContainer to="/" className="nav-logo">
                    <Navbar.Brand>
                        <img
                            src="img/logo.png"
                            width="30"
                            height="30"
                            className="nav-logo d-inline-block align-top"
                            alt="Лого"
                        />
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/" exact>
                            <Nav.Link>Главная</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/faq">
                            <Nav.Link>Вопросики</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/wishlist">
                            <Nav.Link>Подарки</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/game">
                            <Nav.Link>Игрулька</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>;