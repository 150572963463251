import {Question} from "./models";
import {request} from "./api";

export const getQuestions = (): Promise<Question[]> =>
    request<Question[]>('questions');

export const deleteQuestion = (id: number): Promise<void> =>
    request<void>('questions/' + id, {}, {}, 'DELETE');

export const addQuestion = (q: Omit<Question, "id">): Promise<Question> =>
    request<Question>('questions', {}, q, 'POST');

