import {Wish} from "./models";
import {request} from "./api";

export const getWishes = (): Promise<Wish[]> =>
    request<Wish[]>('wishlist');

export const deleteWish = (id: number): Promise<void> =>
    request<void>('wishlist/' + id, {}, {}, 'DELETE');

export const addWish = (q: Omit<Wish, "id">): Promise<Wish> =>
    request<Wish>('wishlist', {}, q, 'POST');

