import {Question} from "../../api/models";
import {useState} from "react";
import {addQuestion} from "../../api/questions";

type AddQuestionProps = {
    onQuestionAdd: (q: Question) => void;
}

export const AddQuestion = ({onQuestionAdd}: AddQuestionProps) => {
    const [text, setText] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    
    const add = () => {
        addQuestion({
            text, answer
        }).then(onQuestionAdd)
    }
    
    return <div className="add-questions">
        <p>
            <label htmlFor="question">Вопрос:</label>
            <input 
                type="text" 
                id="question" 
                placeholder="Текст вопроса" 
                name="question"
                value={text}
                onChange={e => setText(e.target.value)}
            />
        </p>
        <p>
            <label htmlFor="answer">Ответ:</label>
            <input 
                type="text"
                id="answer"
                placeholder="Текст ответа"
                name="answer"
                value={answer}
                onChange={e => setAnswer(e.target.value)}
            />
        </p>
        
        <button onClick={add}>
            Добавить
        </button>
    </div>;
}