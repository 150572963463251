import React from "react";
import {NavMenu} from "./NavMenu";
import './Shared.scss';
import Particles from "react-tsparticles";

export const Layout = ({children}: React.PropsWithChildren<{}>) => {
    return <>
        <NavMenu/>
        <Particles
            id="tsparticles"
            options={{
                background: {
                    color: {
                        value: "transparent",
                    },
                },
                fpsLimit: 120,
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    move: {
                        direction: "bottom",
                        enable: true,
                        outMode: "out",
                        random: false,
                        speed: 0.5,
                        straight: false,
                    },
                    number: {
                        value: 125,
                    },
                    shape: {
                        type: "image",
                        image: [{
                            src: 'img/microbe_1.png'
                        }, {
                            src: 'img/microbe_2.png'
                        }, {
                            src: 'img/microbe_3.png'
                        }, {
                            src: 'img/microbe_4.png'
                        }]
                    },
                    size: {
                        random: true,
                        value: 30,
                    },
                    rotate: {
                        animation: {
                            enable: true,
                            speed: 1
                        },
                        direction: "clockwise",
                        random: true
                    },
                    opacity: {
                        value: 0.25
                    }
                },
                detectRetina: true,
            }}
        />
        <div className="page-content">
            <img className="large-logo" src="img/logo-lg.png" alt="yogurt & iorving"/>
            {children}
        </div>
    </>
};