import {Score} from "./models";
import {request} from "./api";

export const getScores = (): Promise<Score[]> =>
    request<Score[]>('scores');

export const addScore = (value: number): Promise<void> => {
    return request(
        'scores?value=' + value, 
        undefined, 
        {'message': 'pls dont hack this website'}, 
        'POST'
    );
};

