import React, {useContext} from 'react';
import {Letter} from "./Letter";
import './Home.scss';
import {ShortInfo} from "./ShortInfo";
import {WeddingContext} from "../../WeddingContext";

export const Home = () => {
    const {guest} = useContext(WeddingContext);
    
    return <>
        {!!guest && <Letter backId={guest.letterId}/>}
        <ShortInfo guest={guest} />
    </>;
};
    