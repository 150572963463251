import * as THREE from "three"
import {useRef} from "react"
import {useFrame, useLoader} from "@react-three/fiber"

const toneMapping = false;
const sideColor = "#eee";

export type CardProps = {
    backId: number;
}

export const Card = ({backId}: CardProps) => {
    const ref = useRef();
    
    const texFront = useLoader(THREE.TextureLoader, '/static_content/front.png');
    const texBack = useLoader(THREE.TextureLoader, `/static_content/letters/${backId}.png`);

    // apply good anisotropic filtering because card's awful otherwise
    texFront.anisotropy = 16;
    texBack.anisotropy = 16;

    // Make it float
    useFrame((state) => {
        const t = state.clock.getElapsedTime();
        // @ts-ignore
        ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, Math.cos(t / 2) / 10 - 0.25, 0.1)
        // @ts-ignore
        ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, Math.cos(t / 4) / 10, 0.1)
        // @ts-ignore
        ref.current.rotation.z = THREE.MathUtils.lerp(ref.current.rotation.z, Math.cos(t / 4) / 20, 0.1)
        // @ts-ignore
        ref.current.position.y = THREE.MathUtils.lerp(ref.current.position.y, (-5 + Math.sin(t)) / 5, 0.1)
    })

    return (
        <group ref={ref} rotation={[0, -10 * Math.PI, 0]}>
            <mesh scale={[1, 1, 1]} position={[0, 1, 0]}>
                <boxGeometry args={[10, 7, 0.04]}/>
                <meshLambertMaterial color={sideColor} toneMapped={toneMapping} attachArray="material"/>
                <meshLambertMaterial color={sideColor} toneMapped={toneMapping} attachArray="material"/>
                <meshLambertMaterial color={sideColor} toneMapped={toneMapping} attachArray="material"/>
                <meshLambertMaterial color={sideColor} toneMapped={toneMapping} attachArray="material"/>
                <meshLambertMaterial map={texFront} toneMapped={toneMapping} attachArray="material"/>
                <meshLambertMaterial map={texBack} toneMapped={toneMapping} attachArray="material"/>
            </mesh>
        </group>
    )
}