import {useCallback, useContext, useEffect, useState} from "react";
import {Loading} from "../shared/Loading";
import {Wish} from "../../api/models";
import {WeddingContext} from "../../WeddingContext";
import {Col, Container, Row} from "react-bootstrap";
import {deleteWish, getWishes} from "../../api/wishlist";
import {AddWish} from "./AddWish";
import {isDesktop} from "react-device-detect";

export const Wishlist = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [wishes, setWishes] = useState<Wish[]>([]);
    const {isAdmin} = useContext(WeddingContext);

    const loadQuestions = () => {
        getWishes()
            .then(w => {
                setWishes(w);
                setIsLoading(false);
            })
            .catch(console.log)
    };

    const del = (id: number) => {
        deleteWish(id)
            .then(() => console.log('deleted wish'))
            .then(() => {
                setWishes(wishes.filter(w => w.id !== id));
            });
    };

    const onAdded = useCallback((q: Wish) => {
        wishes.push(q);
        loadQuestions();
    }, [wishes]);

    useEffect(() => loadQuestions(), [setIsLoading, setWishes]);
    
    const getTarget = (w: Wish) => {
      if (w.targets.length > 1) {
          return 'Егору и Уле';
      } else if (w.targets.length === 0) {
          return '';
      } else if (w.targets[0] === 'Yegor') {
          return 'Егору';
      } else {
          return 'Уле';
      }
    };

    return <div className="wishlist">
        {isLoading && <Loading/>}

        <Container className="wishes-list">
            <Row className="justify-content-center pb-5">
                <Col lg={6}>
                    Вообще, подарки необязательны, правда. Но если вам очень хочется, то мы составили небольшой список.
                </Col>
            </Row>
            <Row className="justify-content-center pb-5">
                <Col lg={6}>
                    А еще попросили Сергея организовать в телеграме чат, чтобы можно было договориться кто, что дарит
                    или скинуться. Напишите <a href="https://t.me/seryiza">ему</a> для подробностей.
                </Col>
            </Row>
            {wishes.map(w => <Row className="wish justify-content-center" key={w.id}>
                <Col xs={4} lg={4} className="mt-auto mb-auto">
                    {w.name}
                </Col>
                <Col xs={4} sm={4} lg={1} className="mt-auto mb-auto">
                    {getTarget(w)}
                </Col>
                <Col xs={4} sm={4} lg={2} className="mt-auto mb-auto">
                    {w.link && <a href={w.link}>Типа вот</a>}
                </Col>
                {isAdmin && isDesktop && <Col xs={1} className="mt-auto mb-auto">
                    <button onClick={() => del(w.id)}>
                        Удалить
                    </button>
                </Col>}
            </Row>)}
        </Container>
        {isAdmin && !isLoading && <AddWish onWishAdded={onAdded}/>}
    </div>
}