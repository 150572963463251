import {Guest} from "./api/models";
import React, {useState} from "react";
import {getToken} from "./api/token";
import {getMe} from "./api/me";

export type WeddingContextType = {
    guest: Guest | null;
    reloadContext: () => void;
    isAdmin: boolean;
}

export const WeddingContext = React.createContext<WeddingContextType>({
    guest: null,
    isAdmin: false,
    reloadContext: () => {}
});

export const WeddingContextProvider = ({children}: React.PropsWithChildren<{}>) => {
    const [guest, setGuest] = useState<Guest | null>(null);
    
    const reloadContext = () => {
        getMe().then(setGuest);
    }
    
    if (!guest && getToken()) {
        reloadContext();
    }

    return <WeddingContext.Provider value={{guest, isAdmin: guest?.isAdmin ?? false, reloadContext}}>
        {children}
    </WeddingContext.Provider>
}



